<template>
  <v-dialog
    class="patient-auth"
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="400"
    scrollable
  >
    <v-card>
      <v-card-title class="text-h5"> Autenticação </v-card-title>
      <v-card-text>
        Informe seu CPF e data de nascimento.
        <v-form
          @submit.prevent="auth"
          class="text-center mt-4"
          v-model="valid"
          ref="form"
        >
          <div style="max-width: 330px; margin: 0 auto">
            <v-text-field
              label="Digite seu CPF"
              autofocus
              dense
              outlined
              name="username"
              v-model="form.cpf"
              :rules="$rules.required"
              v-mask.raw="'###.###.###-##'"
            ></v-text-field>
            <v-text-field
              v-model="form.birthday"
              :rules="$rules.required"
              name="birthday"
              dense
              v-mask.raw="'##/##/####'"
              label="Digite sua data de nascimento"
              outlined
            >
            </v-text-field>

            <v-btn
              block
              type="submit"
              :loading="loading"
              elevation="1"
              color="primary"
            >
              Continuar
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import { sortBy } from 'lodash'
export default {
  name: 'PatientAuth',
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    patientId: null,
    onSuccess: null,
    form: {
      cpf: '',
      birthday: '',
    },
  }),
  methods: {
    open(patientId, onSuccess = null) {
      this.patientId = patientId
      this.onSuccess = onSuccess
      this.dialog = true
    },

    _parsePatient(patient) {
      const phoneTypes = ['Celular', 'Residencial', 'Comercial']
      const contacts = sortBy(patient.contatos, 'tipo.descricao', phoneTypes)
      const phones = []

      const age =
        patient.data_nascimento == null
          ? null
          : this.$moment().diff(
              this.$moment(patient.data_nascimento, 'YYYYMMDD'),
              'years'
            )

      contacts.forEach(contact => {
        if (phoneTypes.includes(contact.tipo.descricao)) {
          phones.push(`${contact.ddd}${contact.numero}`)
        }
      })

      const birthday =
        patient.data_nascimento == null
          ? null
          : this.$moment(patient.data_nascimento, 'YYYYMMDD').format(
              'DD/MM/YYYY'
            )

      const sex = patient.sexo == null ? null : patient.sexo.descricao
      return {
        code: patient.codigo,
        cpf: patient.cpf,
        name: patient.nome,
        deficient: patient.ic_deficiente,
        birthday: birthday,
        old: age >= 65,
        email: patient.email,
        phones: phones.join(', '),
        sex: sex,
      }
    },

    auth() {
      this.loading = true

      Api.patient(this.patientId)
        .then(response => {
          const patient = this._parsePatient(response.data.patient)

          const cpf = this.$raw(this.form.cpf)
          const birthday = this.form.birthday
          const isValid = patient.cpf == cpf && patient.birthday == birthday

          if (isValid) {
            if (typeof this.onSuccess === 'function') {
              this.onSuccess(patient)
            }
            this.dialog = false
          } else {
            this.$toast.show('Paciente não encontrado', 'error')
          }
        })
        .catch(error => {
          if (error.response) {
            this.$toast.show('Paciente não encontrado', 'error')
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loading = false
        })
      // console.log('auth')
    },
  },
}
</script>
