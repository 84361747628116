<template id="public-medical-report">
  <div style="height: 100%; width: 100%; background: #f6f7fb">
    <v-container style="height: 100%">
      <PatientAuthDialog ref="patientAuthDialog" />
      <v-row class="mt-4 mb-8" align="center" no-gutters justify="center">
        <img
          style="max-height: 45px; max-width: 100px"
          alt="Logo"
          class="ui centered image client-logo"
          :src="organization.logo"
        />
      </v-row>
      <v-row align="center" no-gutters justify="center" v-if="loading">
        <v-progress-circular
          indeterminate
          size="45"
          color="primary"
          style="margin-top: 100px"
        ></v-progress-circular>
      </v-row>
      <v-alert
        text
        outlined
        color="deep-orange"
        icon="mdi-alert-circle"
        v-if="showNotAllowedError"
      >
        O paciente selecionado não permite acesso aos seus laudos através do
        link direto. Para visualizar este laudo, acesse a plataforma de
        resultados com seu login e senha
        <router-link :to="{ path: '/login' }">clicando aqui.</router-link>
      </v-alert>
      <v-row v-show="success">
        <v-col>
          <PatientCard ref="patientCard" />
        </v-col>
      </v-row>
      <div
        v-show="success"
        class="tabs-container"
        style="background-color: white"
      >
        <v-row align="center" justify="center">
          <v-col>
            <v-tabs v-model="tab">
              <v-tab class="text-capitalize">Exames de Imagem</v-tab>
              <v-tab class="text-capitalize">Exames Laboratoriais</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item :eager="true">
            <ImageTab ref="imageTab" />
          </v-tab-item>
          <v-tab-item :eager="true">
            <LabTab ref="labTab" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import PatientAuthDialog from '@/components/PatientAuthDialog'
import PatientCard from '@/views/public_medical_report/PatientCard.vue'
import ImageTab from './images'
import LabTab from './labs'
import { mapState } from 'vuex'

export default {
  name: 'PublicOrder',
  components: { PatientAuthDialog, PatientCard, ImageTab, LabTab },
  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),
  },
  data() {
    return {
      tab: 0,
      order: null,
      patient: {
        code: null,
      },
      success: false,
      loading: false,
      showNotAllowedError: false,
      exams: [],
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    openPACS(exam) {
      if (this.loadingPACS) {
        this.$toast.show('PACS carregando ...', 'warn')
        return
      }

      this.loadingPACS = true

      const params = {
        pad_isn: this.$hashids.encode(exam.pad_isn),
        pac_cod: this.patient.code,
        emp_sigla: this.order.emp_sigla,
        pex_data: this.order.pex_data,
        sgr_codmoddicom: exam.sgr_codmoddicom,
        emp_cod: this.order.emp_cod,
        pac_datanasc: this.patient.birthday,
        pac_nome: this.patient.name,
      }

      Api.pacs(params)
        .then(response => {
          window.open(response.data.url, '_blank')
        })
        .catch(error => {
          this.$toast.show('Erro ao carregar PACS', 'error')
          throw error
        })
        .finally(() => {
          this.loadingPACS = false
        })
    },
    async loadData() {
      this.loading = true

      try {
        // decode token
        const tokenReponse = await Api.decode(this.$route.params.token)
        const decoded = tokenReponse.data.decoded
        const patientId = String(decoded[1]).padStart(8, '0')

        // check permission
        const userSettingsResponse = await Api.patientSettings(patientId)
        const userSettings = userSettingsResponse.data.settings || {}

        const allowsDirectLink =
          userSettings.allows_direct_link_to_medical_report == null
            ? true
            : userSettings.allows_direct_link_to_medical_report

        if (!allowsDirectLink) {
          this.showNotAllowedError = true
          this.loading = false
          return
        }

        // check auth
        this.$refs.patientAuthDialog.open(patientId, async patient => {
          // get order
          const orderReponse = await Api.orders.public(this.$route.params.token)
          const images = orderReponse.data.order.images.exams
          const labs = orderReponse.data.order.labs.orders

          this.$refs.imageTab.setData(images)
          this.$refs.labTab.setData(labs)

          this.$refs.patientCard.setPatient(patient)
          this.success = true
        })
      } catch (error) {
        if (error.response) {
          this.$toast.show('Erro ao buscar dados', 'error')
        } else {
          throw error
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped>
#public-medical-report {
  color: #5e6368;
}

.tabs-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  padding: 1rem;
  margin-top: 1rem;
  min-height: calc(100vh - 285px);
}
</style>
